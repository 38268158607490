import React from 'react';
import Fadian from './Fadian';

export interface ExperienceProps {}

const Experience: React.FC<ExperienceProps> = (props) => {
    return (
        <div className="site-page-content">
            <div style={styles.headerContainer}>
                <div style={styles.header}>
                    <div style={styles.headerRow}>
					<h1 style={{ marginLeft: -16 }}>RL團隊經驗</h1>
                        <a
                            rel="noreferrer"
                            target="_blank"
                            href={'https://www.robolink.ai'}
                        >
                            <h4>www.robolink.ai</h4>
                        </a>
                    </div>
                    <div style={styles.headerRow}>
                        <h3>我們的經驗背景</h3>
                        <b>
                            <p>RL團隊</p>
                        </b>
                    </div>
                </div>
            </div>
            <div className="text-block">
                <p>
                    RL團隊成員來自不同的文化背景，團隊是一隻由香港的科技愛好狂、機器人愛好者、IT狂熱者組成的精英團隊。我們的團隊成員在不同領域擁有豐富的經驗，致力於將AI人工智能技術帶入新的前沿。
                </p>
                <br />
            </div>
            <div style={styles.headerContainer}>
                <div style={styles.header}>
                    <div style={styles.headerRow}>
					<h1 style={{ marginLeft: -16 }}>RL團隊所獲得的經驗值</h1>
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href={'https://www.robolink.ai/'}
                        >
                            <h4>www.robolink.ai</h4>
                        </a>
                    </div>
                    <div style={styles.headerRow}>
                        <h3>XP + ???</h3>
                        <b>
                            <p>RoboLink Team</p>
                        </b>
                    </div>
                </div>
            </div>
            <div className="text-block">
                <h3 style={styles.indent}>漲了好多經驗</h3>
                <ul>
                    <li style={styles.row}>
                        <p>• 推出了一款創新的虛擬現實遊戲，利用先進的虛擬現實技術和交互設計，為玩家帶來沈浸式的遊戲體驗，並贏得了廣泛的贊譽和用戶好評。</p>
                        <p>2022/04/21
                            [   
								100 XP
                            ]
                        </p>
                    </li>
                    <li style={styles.row}>
                        <p>• 推出了一款創意數字藝術平臺，為藝術家提供一個展示和銷售他們作品的在線平臺，通過智能合約和區塊鏈技術，確保作品的版權和交易的安全性。</p>
                        <p>2022/06/15
                            [
                                100 XP
                            ]
                        </p>
                    </li>
                    <li style={styles.row}>
                        <p>• 設計和發布了一款創意社交媒體APP，該應用程序結合了社交網絡、音樂和藝術，為用戶提供一個獨特的創意交流平臺。</p>
                        <p>2022/09/02
                            [
                                100 XP
                            ]
                        </p>
                    </li>
                    <li style={styles.row}>
                        <p>• 開發了一款在線教育平臺，通過結合人工智能和教育理論，為學生提供個性化的學習內容和輔導服務，幫助他們更高效地學習和成長。</p>
                        <p>2022/12/05
                            [
                                100 XP
                            ]
                        </p>
                    </li>
                    <li style={styles.row}>
                        <p>• 
                            開發了一款智能聊天機器人，它具備自然語言處理和機器學習技術，能夠與用戶進行智能對話，並提供個性化的建議和服務。</p>
                        <p>2023/03/13
                            [
                                100 XP
                            ]
                        </p>
                    </li>
                    <li style={styles.row}>
                        <p>• 發布了一款創新的智能算法系統，利用機器學習和個性化算法，為用戶提供個性化內容，更輕松地發現他們感興趣的內容，並開放了API。</p>
                        <p>2023/04/25
                            [
                                100 XP
                            ]
                        </p>
                    </li>
                </ul>
            </div>
        </div>
    );
};

const styles: StyleSheetCSS = {
    header: {
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: '100%',
    },
    skillRow: {
        flex: 1,
        justifyContent: 'space-between',
    },
    skillName: {
        minWidth: 56,
    },
    skill: {
        flex: 1,
        padding: 8,
        alignItems: 'center',
    },
    progressBar: {
        flex: 1,
        background: 'red',
        marginLeft: 8,
        height: 8,
    },
    hoverLogo: {
        height: 32,
        marginBottom: 16,
    },
    headerContainer: {
        alignItems: 'flex-end',
        width: '100%',
        justifyContent: 'center',
    },
    hoverText: {
        marginBottom: 8,
    },
    indent: {
        marginLeft: 24,
    },
    headerRow: {
        justifyContent: 'space-between',
        alignItems: 'flex-end',
    },
    row: {
        display: 'flex',
        justifyContent: 'space-between',
    },
};

export default Experience;
