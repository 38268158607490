import React, { useState } from 'react';
// @ts-ignore
import house from '../../../assets/audio/house_master.mp3';
// @ts-ignore
import edge from '../../../assets/audio/edge_unmastered.mp3';
// @ts-ignore
import dnb from '../../../assets/audio/break.mp3';
// @ts-ignore
import dnbDrums from '../../../assets/audio/dnb_drop_drums.mp3';
import houseProject from '../../../assets/pictures/projects/audio/houseProject.png';
import dnbDrumsProject from '../../../assets/pictures/projects/audio/dnbDrumsProject.png';

export interface FriendlinkProjectsProps {}

const FriendlinkProjects: React.FC<FriendlinkProjectsProps> = (props) => {
    const [currentSong, setCurrentSong] = useState<string>('');

    return (
        <div className="site-page-content">
            <h1>合作/開發者方案</h1>
            <h3>你可以義無反顧的選擇加入我們</h3>
            <br />
            <div className="text-block">
            <hr />
                <p>
                我們相信在選擇合作夥伴時，關鍵是找到一個值得信賴、具有創新能力和卓越技術的團隊。RoboLink正是您無法忽視的合作夥伴。讓我們一起探索為何與RoboLink合作將是您的明智之選：
                <br /><br />
1️⃣ 技術領先：RoboLink團隊是機器人技術和人工智能領域的領導者。我們擁有一支高度熟練和經驗豐富的團隊，精通各種先進技術和工具。我們致力於將最新的科技和創新應用於實際項目中，以滿足客戶的需求並取得卓越的成果。
<br /><br />
2️⃣ 客戶至上：我們將客戶的需求置於首位。與RoboLink合作，您將受益於我們個性化的解決方案，專註於滿足您獨特的業務目標和挑戰。我們與客戶緊密合作，傾聽和理解您的需求，確保交付的解決方案完全符合您的期望。
<br /><br />
3️⃣ 創新驅動：RoboLink是一個充滿創造力和創新精神的團隊。我們不斷尋求突破，挑戰常規，並在技術和業務應用方面推動行業的發展。與我們合作，您將獲得獨特而前瞻性的解決方案，幫助您在競爭激烈的市場中脫穎而出。
<br /><br />
4️⃣ 協作合作：我們相信合作和協作的力量。與RoboLink合作，您將獲得一個富有合作精神的夥伴，我們將與您共同探索機會、克服挑戰，並實現共同的成功。我們珍視與客戶和合作夥伴之間的緊密關系，致力於建立長期的合作夥伴關系。
<br /><br />
5️⃣ 可靠性和透明度：RoboLink以其高度可靠的交付能力和透明的工作方式而聞名。我們遵守承諾，按時交付，並保持與客戶的溝通暢通。我們的合作關系建立在誠信和互信的基礎上，您可以放心地與我們合作，知道您的項目將得到妥善管理和關註。
<br /><br />
🚀 技術創新：RoboLink團隊不斷追求技術創新，保持在機器人和人工智能領域的前沿地位。我們將為您提供最新的技術解決方案，幫助您實現業務增長和競爭優勢。
<br /><br />
🤝 個性化定製：我們深知每個合作夥伴的獨特性，因此我們將根據您的需求和目標提供個性化的解決方案。我們將與您緊密合作，確保所提供的解決方案與您的品牌和業務完美契合。
<br /><br />
📈 成果導向：RoboLink始終以實現結果為導向。我們致力於為您提供可量化的成果和回報。通過精心規劃和執行，我們將幫助您實現預期的目標，並為您的業務帶來持續增長。
<br /><br />
💡 創意思維：我們鼓勵創意和新思維的發展。與RoboLink合作，您將體驗到一個充滿創造力和創新精神的合作夥伴，我們將為您提供新穎、有趣且與眾不同的解決方案。
<br /><br />
🌐 全球視野：RoboLink是一個具有國際視野的團隊，我們與來自不同地域和行業的客戶合作。無論您的業務在哪個國家或領域，我們都能為您提供全球化的支持和服務。
<br /><br />
選擇與RoboLink合作，您將獲得一支充滿激情、專業且富有創新力的團隊。我們將竭誠為您提供卓越的技術解決方案、持續的支持和合作。讓我們一起開啟一個成功的合作之旅！
<br /><br />
立即與我們聯系，讓我們一起塑造未來！
<br /><br />
最好的問候，
<br /><br />
                </p>
                <br />
                <div style={styles.headerRow}>
                        <h3> </h3>
                        <b>
                            <p>RoboLink團隊</p>
                        </b>
                </div>
			</div>
        </div>
    );
};

// const styles: StyleSheetCSS = {};
const styles: StyleSheetCSS = {
    header: {
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: '100%',
    },
    skillRow: {
        flex: 1,
        justifyContent: 'space-between',
    },
    skillName: {
        minWidth: 56,
    },
    skill: {
        flex: 1,
        padding: 8,
        alignItems: 'center',
    },
    progressBar: {
        flex: 1,
        background: 'red',
        marginLeft: 8,
        height: 8,
    },
    hoverLogo: {
        height: 32,
        marginBottom: 16,
    },
    headerContainer: {
        alignItems: 'flex-end',
        width: '100%',
        justifyContent: 'center',
    },
    hoverText: {
        marginBottom: 8,
    },
    indent: {
        marginLeft: 24,
    },
    headerRow: {
        justifyContent: 'space-between',
        alignItems: 'flex-end',
    },
    row: {
        display: 'flex',
        justifyContent: 'space-between',
    },
};

export default FriendlinkProjects;
