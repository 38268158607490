import React from 'react';
// import DesktopShortcut from '../os/DesktopShortcut';
import Window from '../os/Window';
import useInitialWindowSize from '../../hooks/useInitialWindowSize';

export interface TheporndudeProps extends WindowAppProps {}

const TheporndudeApp: React.FC<TheporndudeProps> = (props) => {
    const { initWidth, initHeight } = useInitialWindowSize({ margin: 300 });
    return (
        <Window
            top={150}
            left={150}
            width={initWidth}
            height={initHeight}
            windowBarIcon="twitter"
            windowTitle="Twitter"
            closeWindow={props.onClose}
            onInteract={props.onInteract}
            minimizeWindow={props.onMinimize}
        >
            <div className="site-page">
                <iframe
                    src="https://twitter.com/RoboLink_AI/"
                    title="Twitter - RoboLink.ai"
                    width="100%"
                    height="100%"
                />
                {/* <div style={}>
                    <DesktopShortcut
                        icon="computerBig"
                        invertText
                        shortcutName={'Computer Details'}
                        onOpen={() => {}}
                    />
                </div> */}
            </div>
        </Window>
    );
};

export default TheporndudeApp;
