import React, { useEffect, useState } from 'react';
import Window from '../os/Window';
import { useInterval } from 'usehooks-ts';
import { motion } from 'framer-motion';
import useInitialWindowSize from '../../hooks/useInitialWindowSize';

export interface CreditsProps extends WindowAppProps {}

const CREDITS = [
    {
        title: '总交互次數 & PROGRESS',
        rows: [
			['总交互', '115310'],
			['Total power of yesterday', '115310']
			],
    },
/*     {
        title: '昨日交互次數榜單 & Ranking of donator',
        rows: [
            ['PPnude', '30 次'],
            ['PPnude 2', '50 次'],
            ['PPnude 3', '60 次'],
            ['PPnude 4', '50 次'],
            ['PPnude 5', '50 次'],
            ['C先生', '350电量'],
            ['D先生', '600 电量'],
            ['E先生', '500电量'],
            ['F先生', '350电量'], 
        ],
    },*/
    {
        title: '终端用户 & USERS',
        rows: [
            ['總用戶', '9130'],
            ['Total Users', '9130'],
        ],
    },
    {
        title: '我們的第三方支持 & Support',
        rows: [
            ['OpenAI', '2023.05'],
            ['Bing', '2022.12'],
            ['BingAI', '2023.09'],
            ['Vercel', '2023.01'],
        ],
    },
    {
        title: '特別鳴謝 & Special Thanks',
        rows: [
            ['社區的所有成員', 'HK BGP算力雲'],
            ['RL志願者', '第三方開發人員'],
        ],
    },
];

const Credits: React.FC<CreditsProps> = (props) => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const [time, setTime] = useState(0);
    const { initWidth, initHeight } = useInitialWindowSize({ margin: 300 });

    // every 5 seconds, move to the next slide
    useInterval(() => {
        setTime(time + 1);
        // setCurrentSlide((currentSlide + 1) % CREDITS.length);
    }, 1500);

    useEffect(() => {
        if (time > 5) {
            setCurrentSlide((currentSlide + 1) % CREDITS.length);
            setTime(0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [time]);

    const nextSlide = () => {
        setTime(0);
        setCurrentSlide((currentSlide + 1) % CREDITS.length);
    };

    return (
        // add on resize listener
        <Window
            top={24}
            left={150}
            width={initWidth}
            height={initHeight}
            windowTitle="Credits"
            windowBarIcon="windowExplorerIcon"
            closeWindow={props.onClose}
            onInteract={props.onInteract}
            minimizeWindow={props.onMinimize}
            bottomLeftText={'© Copyright 2022 Mr.Crab'}
            bottomRightText={(
                <div>
                    <span style={{ marginLeft: 4 }}>发电进程</span>
                </div>
            )}
        >
            <div
                onMouseDown={nextSlide}
                className="site-page"
                style={styles.credits}
            >
                <h2>发电进程</h2>
                <p>With the love of Mr. Crab, 2022</p>
                <br />
                <br />
                <br />
                <div style={styles.slideContainer}>
                    {
                        <motion.div
                            animate={{ opacity: 1, y: -20 }}
                            transition={{ duration: 0.5 }}
                            key={`section-${CREDITS[currentSlide].title}`}
                            style={styles.section}
                        >
                            <h3 style={styles.sectionTitle}>
                                {CREDITS[currentSlide].title}
                            </h3>
                            {CREDITS[currentSlide].rows.map((row, i) => {
                                return (
                                    <div key={`row-${i}`} style={styles.row}>
                                        <p>{row[0]}</p>
                                        <p>{row[1]}</p>
                                    </div>
                                );
                            })}
                        </motion.div>
                    }
                </div>
                <p>Click to continue...</p>
                <br />
                <div style={styles.nextSlideTimer}>
                    {/* make a time number of dots */}
                    {Array.from(Array(time)).map((i) => {
                        return (
                            <div key={i}>
                                <p>.</p>
                            </div>
                        );
                    })}
                </div>
            </div>
        </Window>
    );
};

const styles: StyleSheetCSS = {
    credits: {
        width: '100%',
        backgroundColor: 'black',
        paddingTop: 64,
        flexDirection: 'column',
        alignItems: 'center',
        paddingBottom: 64,
        color: 'white',
        overflow: 'hidden',
    },
    row: {
        overflow: 'none',
        justifyContent: 'space-between',
        flexDirection: 'row',
        width: 600,
        alignSelf: 'center',
    },
    section: {
        overflow: 'none',
        alignItems: 'center',
        flexDirection: 'column',
        marginBottom: 32,
        opacity: 0,
    },
    sectionTitle: {
        marginBottom: 32,
    },
    slideContainer: {
        width: '100%',
        height: '70%',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
    },
    nextSlideTimer: {
        width: 64,
        height: 32,
        justifyContent: 'space-evenly',
        alignItems: 'center',
    },
};

export default Credits;
