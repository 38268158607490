import React, { useEffect, useState, useCallback } from 'react';
import colors from '../../constants/colors';
import twitterIcon from '../../assets/pictures/contact-twitter.png';
import telegramIcon from '../../assets/pictures/contact-tg.png';
import inIcon from '../../assets/pictures/contact-in.png';
import { Link } from 'react-router-dom';


export interface ContactProps {}



// function to validate email
const validateEmail = (email: string) => {
    const re =
        // eslint-disable-next-line
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
};

interface SocialBoxProps {
    icon: string;
    link: string;
}

const SocialBox: React.FC<SocialBoxProps> = ({ link, icon }) => {
    return (
        <a rel="noreferrer" target="_blank" href={link}>
            <div className="big-button-container" style={styles.social}>
                <img src={icon} alt="" style={styles.socialImage} />
            </div>
        </a>
    );
};

const Contact: React.FC<ContactProps> = (props) => {

    return (
        <div className="site-page-content">
            <div style={styles.header}>
                <h1>與RoboLink團隊取得聯繫</h1>
                <div style={styles.socials}>
                    <SocialBox
                        icon={inIcon}
                        link={'https://www.linkedin.com/in/'}
                    />
                    <SocialBox
                        icon={twitterIcon}
                        link={'https://twitter.com/RoboLink_AI'}
                    />
                    <SocialBox
                        icon={telegramIcon}
                        link={'https://t.me'}
                    />
                </div>
            </div>
            <div className="text-block">
                <p>
                我們會定期審查表單回答，並在盡可能快的時間內回復你。Mail[support@robolink.ai]
                </p>
                <br /><hr />
            </div>
            <iframe title="ad" src="https://docs.google.com/forms/d/e/1FAIpQLSdkFOCrOlygWJ3L1BJUil3Ak-M8MULWajHFZxUjzRwBbzksTQ/viewform?embedded=true" frameBorder="" style={{ width: '100%', height: '1080px' }} />
        </div>
    );
};

const styles: StyleSheetCSS = {
    form: {
        flexDirection: 'column',
        marginTop: 32,
    },
    formItem: {
        marginTop: 4,
        marginBottom: 16,
    },
    socialImage: {
        width: 36,
        height: 36,
    },
    buttons: {
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    formInfo: {
        textAlign: 'right',

        flexDirection: 'column',
        alignItems: 'flex-end',
        paddingLeft: 24,
    },
    star: {
        paddingRight: 4,
        color: 'red',
    },
    button: {
        minWidth: 184,
        height: 32,
    },
    header: {
        alignItems: 'flex-end',
        justifyContent: 'space-between',
    },
    socials: {
        marginBottom: 16,
        justifyContent: 'flex-end',
    },
    social: {
        width: 4,
        height: 4,
        // borderRadius: 1000,

        justifyContent: 'center',
        alignItems: 'center',
        marginLeft: 8,
    },
};

export default Contact;