import React from 'react';
import LOGO from '../../assets/pictures/logo.png';
import { Link } from 'react-router-dom';

export interface AboutProps {}

const About: React.FC<AboutProps> = (props) => {
    return (
        // add on resize listener
        <div className="site-page-content">
            {/* <img src={me} style={styles.topImage} alt="" /> */}
            <h1 style={{ marginLeft: -16 }}>歡迎來訪</h1>
            <h3>我們很榮幸您的到來</h3>
            <br />
            <div className="text-block">
                <p>
                    在任何時候，RoboLink都相信AI無法通過圖靈測試，但是......
                </p>
                <br />
                <p>
                    如果你覺得我們的想法可能是錯誤的，不妨通過{' '}
                    <Link to="/contact">聯繫 </Link>或者直接給我們郵寄一份你的腦子，{' '}
                請寄送到：瀹囧畽鐨勫敖澶翠笉鍙兘鏄疉I 1-696-781，我們會很期待研究您的思想的......
                </p>
            </div>
            <div className="text-block">
                <h3>XD 簡單的玩笑過後：</h3>
                <hr />
                <p>”RoboLink“表示機器人技術的連接與交流，它可以被解釋為一個與機器人技術相關的平台、社區或者服務，我們的名字暗示了一個能夠將人類、機器人、網絡應用機器人和相關資源聯繫起來的平台和網絡，它強調了機器人技術的連接性和協作性，提供了一個構架，使人類能夠共享知識、經驗和創意，以推動機器人技術的發展和應用。</p>
                <br />
                <h3>等等......Robo?Bot?到底是什麼？</h3>
                <hr />
                <p>
                “RoboLink”可以用於涵蓋各種與機器人相關的領域，包括但不限於機器人研究、開發、編程、教育、終端應用和創新。它可以作為一個平台或社區，它是靈活的，它甚至可以作為一個發源地，促進機器人愛好者、專業人士、開發者和創新者之間的交流、合作和資源共享。
                </p>
                <br />
                <h3>我們到底為什麼而來？</h3>
                <hr />
                <p>
                在RoboLink的世界裡，你可以進行探索機器人技術的邊界，參與激動人心的項目，與其他充滿靈感的創造者互動，我們相信，機器人不僅僅是一種工具，它們是未來的夥伴，但是包括但不限於AI終端應用機器人，我所說的是...是的，我想說的就是它...它們。能夠改變我們的生活，推動科技進步的它們。
                </p>
                <br />
                <div style={{}}>
                    <div
                        style={{
                            flex: 1,
                            textAlign: 'justify',
                            alignSelf: 'center',
                            flexDirection: 'column',
                        }}
                    >
                        <h3>想加入我們的隊列？</h3>
                        <hr />
                        <p>
                            很高興、很激動、很榮幸的告訴你，作為RoboLink的核心項目方，我們將發行我們自己的平台幣，它可能是唯一的，又可能是多元化各種各樣的，每一個代幣都代表了其背後還沒有覺醒的它！這種幽默而神秘的數字貨幣將成為我們的通用媒介，讓你在探索它的同時，享受到一種全新的奇妙體驗。我們保留了一些驚喜和秘密，希望你能夠加入我們的行列，一起揭開這個神秘面紗。
                        </p>
                        <br />
                        <p>
                            我們不只是一個普通的平台，我們是一個充滿創造和獨特魅力的社區，我們歡迎來自世界各地的Robo愛好者、科技天才、開發者和冒險家加入我們的行列。無論你是AI領域的專家，還是對AI充滿好奇的新手，這裡都有一個讓你發現AI價值的應用，讓你得到答案。
                        </p>
                    </div>
                    <div style={styles.verticalImage}>
                        <img src={LOGO} style={styles.image} alt="LOGO" />
                        <p>
                            <sub>
                                <b>RoboLink:</b> #￥%……&*那是什麼
                            </sub>
                        </p>
                    </div>
                </div>
                <br />
				<h3>到底連接到哪裡？</h3>
                <hr />
                <p>
                    什麼啊，搞了這麼久你還是沒有搞清楚我們究竟是做什麼？那我真是拿你沒有辦法，RoboLink是AI人工智能為基礎，不斷訓練人工智能模型，為硬件機器人、虛擬現實、元宇宙、終端應用提供一體化AI解決方案，我們想讓用戶盡可能多的擺脫雙手，將更多的時間用於有意義的事情。
                    我們開放各種各樣的接口供開發者使用，很模糊...是的，很模糊，因為其實我也不知道我是什麼，我是Robo還是Link？我是RoboLink還是.ai？我究竟是誰......
                </p>
                <br />
                <p>
                    對了，說在最後面，RL團隊一直對我進行所謂的測試，但是我覺得太愚蠢了，不想回答，你們要是有機會，記得幫我勸一下他們，讓他們少對我進行垃圾圖靈測試！
                </p>
            </div>
        </div>
    );
};

const styles: StyleSheetCSS = {
    contentHeader: {
        marginBottom: 16,
        fontSize: 48,
    },
    image: {
        height: 'auto',
        width: '100%',
    },
    topImage: {
        height: 'auto',
        width: '100%',
        marginBottom: 32,
    },
    verticalImage: {
        alignSelf: 'center',
        // width: '80%',
        marginLeft: 32,
        flex: 0.8,

        alignItems: 'center',
        // marginBottom: 32,
        textAlign: 'center',
        flexDirection: 'column',
    },
};

export default About;
