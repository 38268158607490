import React from 'react';
// @ts-ignore
import saga from '../../../assets/pictures/projects/software/saga.mp4';
// @ts-ignore
import computer from '../../../assets/pictures/projects/software/computer.mp4';
// @ts-ignore
import scroll from '../../../assets/pictures/projects/software/scroll.mp4';
import ResumeDownload from '../ResumeDownload';
import VideoAsset from '../../general/VideoAsset';

export interface LandmarkProjectsProps {}

const LandmarkProjects: React.FC<LandmarkProjectsProps> = (props) => {
    return (
        <div className="site-page-content">
            <h1>RoboLink大事記</h1>
            <h3>是啊是啊，我們把所有變動都記在這裡</h3>
            <br />
            <p>
                %……&*（到底是誰在控制這一切.
            </p>
            <br />
            <div className="text-block">
                    <ul>
                        <li style={styles.row}>
                            <p>• 三個人成團的我們成立了，但是我們不叫RoboLink，我們沒有任何名字</p>
                            <p>2022/01/18</p>
                        </li>
                        <li style={styles.row}>
                            <p>• 我們拿到了第一筆市場投資，是給一家遊戲公司開發虛擬現實遊戲（我好像在前面提及到了）</p>
                            <p>2022/08/11</p>
                        </li>
                        <li style={styles.row}>
                            <p>• 團隊迎來了新員工，他一直很神秘，戴著口罩，至此團隊擁有四個人</p>
                            <p>2022/08/18</p>
                        </li>
                        <li style={styles.row}>
                            <p>• 我們嘗試做了第一個硬件機器人，它成功了，但是不夠智能，現在我們把它放在辦公室掃地。</p>
                            <p>2022/11/12</p>
                        </li>
                        <li style={styles.row}>
                            <p>• 我們終於有名字了，就決定叫RoboLink吧！</p>
                            <p>2022/12/12</p>
                        </li>
                        <li style={styles.row}>
                            <p>• RoboLink團隊的技術應用終於迎來了第一家企業合作，我們終於能夠為企業提供AI人工智能解決方案了！</p>
                            <p>2023/01/20</p>
                        </li>
                        <li style={styles.row}>
                            <p>• RoboLink團隊終於決定踏入WEB3.0的浪潮中，並開發出更多AI產品解決方案！</p>
                            <p>2023/02/23</p>
                        </li>
                        <li style={styles.row}>
                            <p>• 我們決定Robo不再是局限於現實中的Robo了，它也可以是終端化應用，只是我們都稱呼它為機器人！</p>
                            <p>2023/04/15</p>
                        </li>
                        <li style={styles.row}>
                            <p>• AI算法完善，我們推出了第一個屬於自己的互聯網終端產品（它是基於Telegram的）</p>
                            <p>2023/04/27</p>
                        </li>
                    </ul>
            </div>
        </div>
		);
};

const styles: StyleSheetCSS = {
    video: {
        width: '100%',
        padding: 12,
    },
    caption: {
        width: '80%',
    },
	row: {
	    display: 'flex',
	    justifyContent: 'space-between',
	},
};

export default LandmarkProjects;
