import React from 'react';


export interface SomethingProjectsProps {}

const SomethingProjects: React.FC<SomethingProjectsProps> = (props) => {
    return (
        <div className="site-page-content">
            <h1>待办的事</h1>
            <h3>Something I want to do</h3>
            <br />
            <p>
                在这里你能看到发电姬目前还未实现但是在计划中的事。
            </p>
            <br />
            <div className="text-block">
                    <ul>
                        <li style={styles.row}>
                            <p>• 发展广告版，拓展变现方式（疫情当下我实在是太穷了</p>
                            <p>pending</p>
                        </li>
                        <li style={styles.row}>
                            <p>• 服务器大迁移，更换一台速度更快的服务器优化图片加载速度，因为都是原图未压缩，所以每一张图片的打开速度都需要2~3秒（太慢了影响观看</p>
                            <p>pending</p>
                        </li>
                        <li style={styles.row}>
                            <p>• 新增对MP4、MOV文件的支持</p>
                            <p>pending</p>
                        </li>
                        <li style={styles.row}>
                            <p>• 新增对RAR文件压缩包的下载支持（可能用网盘的方法取缔，因为带宽费用太高了</p>
                            <p>pending</p>
                        </li>
                        <li style={styles.row}>
                            <p>• 图片查看器支持上下图片翻动查看</p>
                            <p>pending</p>
                        </li>
                        <li style={styles.row}>
                            <p>• 优化发电方式，现在的发电方式太笨拙了</p>
                            <p>pending</p>
                        </li>
                        <li style={styles.row}>
                            <p>• 拓展Twitter、Telegram社区</p>
                            <p>pending</p>
                        </li>
                        <li style={styles.row}>
                            <p>• 收藏10TB资源</p>
                            <p>pending</p>
                        </li>
                    </ul>
            </div>
        </div>
		);
};

const styles: StyleSheetCSS = {
    video: {
        width: '100%',
        padding: 12,
    },
    caption: {
        width: '80%',
    },
	row: {
	    display: 'flex',
	    justifyContent: 'space-between',
	},
};

export default SomethingProjects;
